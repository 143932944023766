.ui.vertical.sidebar.menu {
  > .item {
    border-top: solid 1px #c60a2a;
  }
}

.ui.top.sidebar.mobilemenu {
  background-color: #000000;
}

.sidebar .servicelink {
  border-top: 1px solid #c60a2a;

  a {
    color: #fbc1cb;
  }
}
